import React, { useEffect,useState, useRef } from 'react';
import first5 from './first5.mp4';
import second5 from './second5.mp4';
import { Theme,Button,Dialog } from '@radix-ui/themes';
import {Authenticator, useAuthenticator, CheckboxField} from '@aws-amplify/ui-react';

const signUpDialogStyle = {
  maxWidth: 'fit-content',
  padding: 0,
  border: 'none',
};

const signInDialogStyle = {
  maxWidth: 'fit-content',
  padding: 0,
  border: 'none',
};

function Login() {
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  const video1Ref = useRef<HTMLVideoElement>(null);
  const video2Ref = useRef<HTMLVideoElement>(null);
  const [signUpOpen, setSignUpOpen] = React.useState(false);
  const [signInOpen, setSignInOpen] = React.useState(false);
  const [termsDialogOpen, setTermsDialogOpen] = useState(false);
  const [termsLoaded, setTermsLoaded] = useState(false);

  useEffect(() => {
    const video1 = video1Ref.current;
    const video2 = video2Ref.current;

    if (!video1 || !video2 || authStatus === 'authenticated') {
      // Immediately stop videos if authenticated
      if (video1) {
        video1.pause();
        video1.currentTime = 0;
      }
      if (video2) {
        video2.pause();
        video2.currentTime = 0;
      }
      return;
    }

    // Start with video1 visible and video2 hidden
    video1.style.opacity = '1';
    video2.style.opacity = '0';

    const playSequence = () => {
      video1.currentTime = 0;
      const playPromise1 = video1.play();
      
      // Handle the play promise
      if (playPromise1 !== undefined) {
        playPromise1.catch(() => {
          // Ignore abort errors when video is stopped
        });
      }

      video1.onended = () => {
        video1.style.opacity = '0';
        video2.style.opacity = '1';
        video2.currentTime = 0;
        video2.loop = true;
        
        const playPromise2 = video2.play();
        if (playPromise2 !== undefined) {
          playPromise2.catch(() => {
            // Ignore abort errors when video is stopped
          });
        }

        video2.onended = null;
      };
    };

    playSequence();

    // Cleanup function
    return () => {
      if (video1) {
        video1.pause();
        video1.currentTime = 0;
        video1.onended = null;
      }
      if (video2) {
        video2.pause();
        video2.currentTime = 0;
        video2.onended = null;
      }
    };
  }, [authStatus]);

  // Add this useEffect to load the GetTerms script when dialog opens
  useEffect(() => {
    if (termsDialogOpen) {
      setTermsLoaded(false);
      const script = document.createElement('script');
      script.src = "https://app.getterms.io/dist/js/embed.js";
      script.id = "getterms-embed-js";
      document.body.appendChild(script);
      setTermsLoaded(true);
      return () => {
        // Cleanup script when dialog closes
        const existingScript = document.getElementById("getterms-embed-js");
        if (existingScript) {
          existingScript.remove();
        }
      };
    }
  }, [termsDialogOpen]);



  // Styles for the container and videos
  const containerStyle: React.CSSProperties = {
    position: 'relative',
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
  };

  const videoStyle: React.CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  // Update the button container style to be bottom middle
  const buttonContainerStyle: React.CSSProperties = {
    position: 'absolute',
    bottom: '5%',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 10,
  };

  return (
    <>
      {authStatus !== 'authenticated' && (
        <Theme>
          <div style={containerStyle}>
            <video
              ref={video1Ref}
              src={first5}
              muted
              playsInline
              preload="auto"
              style={{ ...videoStyle, opacity: 1 }}
            />
            <video
              ref={video2Ref}
              src={second5}
              muted
              playsInline
              preload="auto"
              style={{ ...videoStyle, opacity: 0 }}
            />
            <div style={buttonContainerStyle}>
              <Dialog.Root open={signUpOpen} onOpenChange={setSignUpOpen}>
              <Dialog.Trigger>
              <div className="btn-overlay-container">
                <Button 
                  size="4" 
                  className="btn btn-primary"
                  style={{
                    width: '100%',
                    margin: 0,
                    background: 'transparent',
                    position: 'relative',
                    zIndex: 1,
                    overflow: 'hidden'
                  }}
                >
                  <span className="btn-text">Get Started</span>
                </Button>
                <video 
                  autoPlay 
                  loop 
                  muted 
                  playsInline 
                  className="btn-overlay"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    opacity: 1,
                    mixBlendMode: 'overlay',
                    pointerEvents: 'none',
                    zIndex: 2,
                    transform: 'scale(7)',
                    transformOrigin: 'center center'
                  }}
                >
                  <source src="/35mm_G3_DIRTY_2s.mp4" type="video/mp4" />
                </video>
              </div>
            </Dialog.Trigger>
                <Dialog.Content style={signUpDialogStyle}>
                    <Authenticator
                      key={`signup-${signUpOpen}`}
                      initialState="signIn"
                      socialProviders={[]}
                      components={{
                        SignUp: {
                          FormFields() {
                            const { validationErrors } = useAuthenticator();
                            return (
                              <>
                                <Authenticator.SignUp.FormFields />
                                <CheckboxField
                                  errorMessage={validationErrors.acknowledgement as string}
                                  hasError={!!validationErrors.acknowledgement}
                                  name="acknowledgement"
                                  value="yes"
                                  label={
                                    <span>
                                      I agree with the{' '}
                                      <a
                                        href="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setTermsDialogOpen(true);
                                        }}
                                        style={{ textDecoration: 'underline' }}
                                      >
                                        Terms and Conditions
                                      </a>
                                    </span>
                                  }
                                />
                              </>
                            );
                          },
                        },
                      }}
                      services={{
                        async validateCustomSignUp(formData) {
                          if (!formData.acknowledgement) {
                            return {
                              acknowledgement: 'You must agree to the Terms and Conditions',
                            };
                          }
                        },
                      }}
                    />
                </Dialog.Content>
              </Dialog.Root>
            </div>
          </div>
          {/* Update the Terms Dialog */}
          <Dialog.Root open={termsDialogOpen} onOpenChange={setTermsDialogOpen}>
            <Dialog.Content style={{ maxWidth: '800px', maxHeight: '80vh', overflow: 'auto' }}>
              <Dialog.Title>Terms and Conditions</Dialog.Title>
              {!termsLoaded && (
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: '200px'
                }}>
                  <div className="loading-spinner" style={{
                    width: '40px',
                    height: '40px',
                    border: '4px solid #f3f3f3',
                    borderTop: '4px solid #3498db',
                    borderRadius: '50%',
                    animation: 'spin 1s linear infinite'
                  }} />
                </div>
              )}
              <div
                className="getterms-document-embed"
                data-getterms="RRt2r"
                data-getterms-document="tos"
                data-getterms-lang="en-us"
                data-getterms-mode="direct"
                style={{ visibility: termsLoaded ? 'visible' : 'hidden' }}
              />
              <Dialog.Close>
                <Button size="2" variant="soft">Close</Button>
              </Dialog.Close>
            </Dialog.Content>
          </Dialog.Root>

          {/* Add this style for the spinner animation */}
          <style>
            {`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
          </style>
        </Theme>
      )}
    </>
  );
}

export default Login;
