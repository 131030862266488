import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import type { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Button } from '@radix-ui/themes';
import { Component1Icon } from '@radix-ui/react-icons';
import config from '../aws-exports';
import './header.css';
import { UserContext } from '../App';
import whiteOverlay from './New-Head.png';

Amplify.configure(config);

const SignOutIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    style={{ display: 'block' }}
  >
    <path d="M14 2h5a1 1 0 011 1v18a1 1 0 01-1 1h-5" />
    <path d="M9 6l-6 6 6 6" />
    <path d="M3 12h11" />
  </svg>
);

const MarketplaceBagIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    style={{ display: 'block' }}
  >
    <path d="M6 2l.01 4M18 2l-.01 4M3 6h18v14a2 2 0 01-2 2H5a2 2 0 01-2-2V6z" />
    <path d="M8 6a4 4 0 008 0" />
  </svg>
);

export function Header({ signOut }: WithAuthenticatorProps) {
  const { token, user } = useContext(UserContext);

  const isAdmin =
    Array.isArray(token?.payload['cognito:groups']) &&
    token?.payload['cognito:groups'].includes('admin');

  return (
    <header>
      {/* Shop button */}
      <div className="marketplace-icon-container">
        <a
          href="https://filmassistantio.printful.me/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <MarketplaceBagIcon />
        </a>
      </div>

      {/* Token counter */}
      <div className="token-counter-container">
        <div className="token-counter">
          <span className="token-count">
            <Component1Icon />
            {user?.cap}
          </span>
          <span className="token-label">
            Tokens Remaining
          </span>
        </div>
      </div>

      {/* Main header content */}
      <div className="header-wrapper">
        <div className="header-left">
          <div className="logo-section">
            <img
              src={whiteOverlay}
              alt="Logo"
              className="header-logo"
            />
          </div>

          <nav className="nav-section">
            <NavLink to="/home">Home</NavLink>
            <NavLink to="/profile">Profile</NavLink>
            <NavLink to="/prices">Pricing</NavLink>
            <NavLink to="/community">Community</NavLink>
            {isAdmin && <NavLink to="/events">Events</NavLink>}
          </nav>
        </div>

        <div className="signout-section">
          <Button 
            variant="ghost" 
            color="gray" 
            className="signout-button" 
            onClick={signOut}
          >
            <SignOutIcon />
          </Button>
        </div>
      </div>
    </header>
  );
}

export default withAuthenticator(Header);