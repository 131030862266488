import React, { ForwardRefRenderFunction } from 'react';
import { useNavigate } from 'react-router-dom';

interface Feature {
  text: string;
}

interface TierData {
  name: string;
  price: string;
  credits: string;
  models: string;
  features: Feature[];
}

interface Tiers {
  member: TierData;
  memberRefill: TierData;
  tokenPurchase: TierData;
}

interface PricingCardProps {
  tier: keyof Tiers;
  isPopular: boolean;
}

interface PricingSectionProps {
  // Add any props here if needed
}

const PricingCard: React.FC<PricingCardProps> = ({ tier, isPopular }) => {
  const navigate = useNavigate();
  
  const tiers: Tiers = {
    member: {
      name: "Member",
      price: "6",
      credits: "~750 generations per month",
      models: "Full Access",
      features: [
        { text: "Access to Community" },
        { text: "Access to Regular Contests" },
        { text: "Better Rate on Tokens" },
        { text: "Premium Support" },
        { text: "Increased story storage" },
        { text: "Priority access to new features" },
        { text: "Access to all generation types" }
      ]
    },
    memberRefill: {
      name: "Member Refill",
      price: "3",
      credits: "~750 additional generations",
      models: "Member Access Only",
      features: [
        { text: "Use anytime" },
        { text: "Never expires" },
        { text: "Stack multiple refills" },
        { text: "All member benefits" },
        { text: "Best value for active users" },
        { text: "Flexible usage" }
      ]
    },
    tokenPurchase: {
      name: "Token Purchase",
      price: "8",
      credits: "~750 generations",
      models: "Basic Access",
      features: [
        { text: "One-time purchase" },
        { text: "Create stories" },
        { text: "Basic support" },
        { text: "No subscription required" },
        { text: "Basic access to features" }
      ]
    }
  };

  const tierData = tiers[tier];

  return (
    <div className={`pricing-card ${isPopular ? 'popular' : ''}`}>
      {isPopular && <div className="popular-badge">Most popular</div>}
      
      <h3>{tierData.name}</h3>
      <div className="price-container">
        <span className="price">${tierData.price}</span>
        {tier !== 'tokenPurchase' && <span className="period">/month</span>}
      </div>

      <button 
        className="subscribe-button" 
        onClick={() => navigate('/login')}
      >
        Subscribe →
      </button>

      <div className="main-features">
        <div className="feature-item highlight">
          <span>✓</span> {tierData.credits}
        </div>
        <div className="feature-item highlight">
          <span>✓</span> {tierData.models}
        </div>
      </div>

      <div className="features-list">
        {tierData.features.map((feature: Feature, index: number) => (
          <div key={index} className="feature-item">
            <span>✓</span> {feature.text}
          </div>
        ))}
      </div>
    </div>
  );
};

const PricingSection: ForwardRefRenderFunction<HTMLElement, PricingSectionProps> = (props, ref) => {
  return (
    <section className="pricing-section" ref={ref}>
      <div className="content-wrapper">
        <h2 className="pricing-title">Choose Your Plan</h2>
        <div className="pricing-grid">
          <PricingCard tier="member" isPopular={true} />
          <PricingCard tier="memberRefill" isPopular={false} />
          <PricingCard tier="tokenPurchase" isPopular={false} />
        </div>
      </div>
    </section>
  );
};

export default React.forwardRef<HTMLElement, PricingSectionProps>(PricingSection);