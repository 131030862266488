import React from 'react';
import { Amplify } from 'aws-amplify';
import Header from './header';
import Footer from './footer';  
import {useDropzone} from 'react-dropzone';
import { useState , useEffect, useContext} from "react";
import {useForm} from "react-hook-form";
import { fetchUserAttributes } from 'aws-amplify/auth';
import { FetchUserAttributesOutput } from 'aws-amplify/auth';
import {updateUserAttribute,type UpdateUserAttributeOutput} from 'aws-amplify/auth';
import { getCurrentUser } from 'aws-amplify/auth';
import { useMutation } from "react-query";
import { Cache } from 'aws-amplify/utils';
import type { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import config from '../aws-exports';
import { Theme, Flex, Text, Button, Grid, TextArea ,TextField,Box, Checkbox,ScrollArea, Tooltip} from '@radix-ui/themes';
import axios from 'axios';
import '@radix-ui/themes/styles.css';
import { DataList,Badge, } from '@radix-ui/themes';
import { ComponentPlaceholderIcon } from '@radix-ui/react-icons';
import { TailSpin } from 'react-loading-icons';
import {UserContext} from '../App';
import whiteOverlay from './Head-color with text.png';
import {toast,Toaster } from 'react-hot-toast';
import { User } from '../models/user';
import { Trash } from 'lucide-react';
import './prices.css';


import './profile.css';
import { useNavigate } from 'react-router-dom';
import { Dialog } from '@radix-ui/themes';

Amplify.configure(config);


export function Profile(props:any) {
  const {user, setUser, signOut, token, loading, data, setData,debouncedSave,zeroLength} = useContext(UserContext);
  const[changeUsername,setChangeUsername] = useState("");
  const[file, setFile] = useState<File | undefined>();
  const[preview, setPreview] = useState<string| ArrayBuffer | null>();
  const [set, setSet] = useState(new Set<string>());
  const [deletingItems, setDeletingItems] = useState(new Set<string>());
  const isDeleteSelectedDisabled = set.size === 0 || deletingItems.size > 0;
  const [hoveredStates, setHoveredStates] = useState<{[key: string]: boolean}>({});
  const [showLoadDialog, setShowLoadDialog] = useState(false);
  const [pendingLoadKey, setPendingLoadKey] = useState<string>("");
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [title, setTitle] = useState<string>("");
  const [privacyOption, setPrivacyOption] = useState(false);
  const [privacyDialogOpen, setPrivacyDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [pendingDeleteKey, setPendingDeleteKey] = useState<string>("");

  const handleMouseEnter = (key: string) => {
    setHoveredStates(prev => ({
      ...prev,
      [key]: true
    }));
  };

  const handleMouseLeave = (key: string) => {
    setHoveredStates(prev => ({
      ...prev,
      [key]: false
    }));
  };


   const selectAll = () => {
    if (user?.works) {
      const allKeys = Object.keys(user.works);
      setSet(new Set(allKeys));
    }
  };
  const clearAll = () => {
    setSet(new Set());
  };

  const addValue = (value: any) => {
    setSet(prevSet => new Set(prevSet.add(value)));
  };

  const removeValue = (value: any) => {
    setSet(prevSet => {
      const newSet = new Set(prevSet);
      newSet.delete(value);
      return newSet;
    });
  };

  const handleCheckboxChange = (key: string, isChecked: boolean) => {
    if (isChecked) {
      addValue(key);
    } else {
      removeValue(key);
    }
  };
  const navigate = useNavigate();

  //delete saved work
  const deleteWork = useMutation({
    mutationFn: async (keys: Set<string> | string) => {
      const keysArray = keys instanceof Set ? Array.from(keys) : [keys];
      const isDeletingCurrentWork = keysArray.includes(data.title);
      const res = await handleDeletion(keys);
      return {res, isDeletingCurrentWork};
      },
      onMutate: (keys: Set<string> | string) => {
        // Add keys to deletingItems when mutation starts
        if (keys instanceof Set) {
          setDeletingItems(new Set([...deletingItems, ...keys]));
        } else {
          setDeletingItems(new Set([...deletingItems, keys]));
        }
      },
      onSuccess: (result:{res: any, isDeletingCurrentWork: boolean}) => {
        const {res, isDeletingCurrentWork} = result;
        if (res.data.statusCode == 200) {
          setSet(new Set());
          toast.success("Work deleted!");
          if (isDeletingCurrentWork) {
            const newData = {
              ...data,
              title: ""
            };
            setData(newData);
            debouncedSave(newData);
          }
          setUser((user: User) => ({...user, works: res.data.body.works}));
          setDeletingItems(new Set());
        } else if (res.data.statusCode == 400) {
          setSet(new Set());
          setDeletingItems(new Set());
          toast.error(res.data.body.error);
        } else {
          toast.error("error");
          setSet(new Set());
          setDeletingItems(new Set());
        }
      },
      onError: (error: any) => {
        toast.error("error");
        setSet(new Set());
        setDeletingItems(new Set());
      },
    });
  
  const handleDeletion = async(keys: Set<string> | string) => {
    const titlesToDelete = keys instanceof Set ? Array.from(keys) : [keys];
    return await axios.post(`${process.env.REACT_APP_URL}/works`, {
      "event": "delete",
      "titles": titlesToDelete,
      "userId": token?.payload['cognito:username'],
    }, 
    { headers: { "Authorization": token.toString()} }
  );
  }

  const deleteSelected = () => {
    if (set.size > 0) {
      deleteWork.mutateAsync(set);
    } else {
      toast.error("No works selected for deletion");
    }
  }

  const handleLoadWork = (key: string) => {
    if (data.title == "") {
      setPendingLoadKey(key);
      setShowLoadDialog(true);
    } else {
      mutateSave.mutateAsync(data.title);
      executeLoad(key);
    }
  };

  const executeLoad = (key: string) => {
    setData((data: any) => ({...data, ...user?.works[key]}));
    debouncedSave(user?.works[key]);
    navigate('/');
  };

  //useMutation hook for summary
 const mutateSave = useMutation({
  mutationFn:(title: string) => {
    return save(title);
  },
  onSuccess: (res: any) => {
    if (res.data.statusCode == 200) {
      setUser((user: User) => ({...user, works: res.data.body.works}));
    }
  },
  onError: (error: any) => {
    console.log("error");
  },
});

const mutatePrivacy = useMutation({
  mutationFn: async() => {
    return await handlePrivacy();
  },
  onSuccess: (res: any) => {
    if (res.data.statusCode == 200) {
      setUser((user: User) => ({...user, privacy: res.data.body.privacy}));
      setPrivacyDialogOpen(false);
    }
  },
  onError: (error: any) => {
    console.log("error");
  },
});

const handlePrivacy = async() => {
  return await axios.post(`${process.env.REACT_APP_URL}/works`, {
    "event": "privacy",
    "userId": token?.payload['cognito:username'],
    "privacy": privacyOption,
  },
  { headers: { "Authorization": token.toString()} }
);
}

const save = async(title: string) => {
  return await axios.post(`${process.env.REACT_APP_URL}/works`, {
    "event": "save",
    "title": title,
    "userId":token?.payload['cognito:username'],
    "M": data.M,
    "T": data.T,
    "G": data.G,
    "CQ": data.CQ,
    "SUM": data.SUM,
    "S1": data.S1,
    "S2": data.S2,
    "S3": data.S3,
    "S4": data.S4,
    "S5": data.S5,
    "S6": data.S6,
    "S7": data.S7,
    "S8": data.S8,
    "S9": data.S9
  },
  { headers: { "Authorization": token.toString()} }
  );
}
  if (loading == false) {
    return (
      <>
        <div
          style={{
            position: 'relative',
            left: '50%',
            top: '18.75rem',
          }}
        >
      <TailSpin  stroke="#FFA500" speed="1.3" />
        </div>
      </>
    );
  } else {
    return (
      <>
        <Theme>
          <Toaster position="top-center" reverseOrder={false} />
          <Header />
          <div className="app-container" style={{ paddingBottom: '3rem' }}>
            <div className="gradient-background"></div>
            <Flex direction="column" justify="center" align="center" style={{ flexGrow: 1, position: 'relative' }}>
              <h1 className="page-header">Profile</h1>
              {
                <Dialog.Root open={privacyDialogOpen} onOpenChange={setPrivacyDialogOpen}>
                  <Dialog.Trigger>
                    <Button 
                      className="manage-subscription-button"
                      variant="ghost"
                      style={{ 
                        position: 'absolute',
                        top: '1rem',
                        right: '2rem',
                      }}
                    >
                      Privacy Settings
                    </Button>
                  </Dialog.Trigger>
                  <Dialog.Content>
                  <Dialog.Title>Privacy Settings</Dialog.Title>
                  <Flex align="start" style={{ paddingBottom: '1rem' }}>
                      <a 
                        className='terms' 
                        href="https://app.getterms.io/view/RRt2r/privacy/en-us" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}
                      >
                      Privacy Policy
                      </a>
                    </Flex>
                    <Dialog.Description mb="4">
                      <Flex align="center" gap="2">
                        Allow filmassistant.io to use your User Generated Content for commercial or development use?:{' '}
                        <Text weight="bold">Disallowed</Text>
                        <Tooltip 
                          content="Privacy Settings are set to reflect that filmassistant.io retains no license to use User Generated Content for any commerical or development purposes that are not explicitly consented to by the user. For any questions or concerns, please reach out to accountservices@filmassistant.io"
                          side="right"
                          style={{ maxWidth: '300px', whiteSpace: 'normal', wordWrap: 'break-word' }}
                        >
                          <div style={{ cursor: 'help', display: 'inline-flex' }}>
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ color: '#666' }}
                            >
                              <path
                                d="M7.5 1.75C4.26472 1.75 1.75 4.26472 1.75 7.5C1.75 10.7353 4.26472 13.25 7.5 13.25C10.7353 13.25 13.25 10.7353 13.25 7.5C13.25 4.26472 10.7353 1.75 7.5 1.75ZM0.25 7.5C0.25 3.43629 3.43629 0.25 7.5 0.25C11.5637 0.25 14.75 3.43629 14.75 7.5C14.75 11.5637 11.5637 14.75 7.5 14.75C3.43629 14.75 0.25 11.5637 0.25 7.5Z M7 4.75C7 4.33579 7.33579 4 7.75 4C8.16421 4 8.5 4.33579 8.5 4.75C8.5 5.16421 8.16421 5.5 7.75 5.5C7.33579 5.5 7 5.16421 7 4.75ZM7 6.5C7 6.22386 7.22386 6 7.5 6C7.77614 6 8 6.22386 8 6.5V10.5C8 10.7761 7.77614 11 7.5 11C7.22386 11 7 10.7761 7 10.5V6.5Z"
                                fill="currentColor"
                                fillRule="evenodd"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                        </Tooltip>

                      </Flex>
                    </Dialog.Description>
                    <Flex direction="column" gap="4">
                    <Flex gap="2" align="start">
                      <label style={{ color: '#666', pointerEvents: 'none' }}>
                        <input
                          type="radio"
                          name="privacyOption"
                          value="yes"
                          checked={false}
                          readOnly
                          disabled
                          style={{ cursor: 'not-allowed', opacity: 0.5 }}
                        />{' '}
                        Yes
                      </label>
                      <label style={{ pointerEvents: 'none' }}>
                        <input
                          type="radio"
                          name="privacyOption"
                          value="no"
                          checked={true}
                          readOnly
                          disabled
                          style={{ cursor: 'not-allowed' }}
                        />{' '}
                        No
                      </label>
                    </Flex>
                    </Flex>
                    <Flex gap="3" mt="4" justify="end">
                      <Dialog.Close>
                        <Button variant="soft">Close</Button>
                      </Dialog.Close>
                    </Flex>
                  </Dialog.Content>
                </Dialog.Root>
              }
            </Flex>
            <Flex direction="column" justify="center" align="center" style={{ flexGrow: 1, position: 'relative' }}>
              <Box className="white-container">
                
                <DataList.Root>
                  <DataList.Item>
                    <DataList.Label>Username</DataList.Label>
                    <DataList.Value>{token?.payload?.preferred_username}</DataList.Value>
                  </DataList.Item>
                  <DataList.Item>
                    <DataList.Label>Email</DataList.Label>
                    <DataList.Value>{token?.payload?.email}</DataList.Value>
                  </DataList.Item>
                  <DataList.Item>
                    <DataList.Label>Tokens</DataList.Label>
                    <DataList.Value>{user?.cap}</DataList.Value>
                  </DataList.Item>
                  <DataList.Item>
                    <DataList.Label>Subscription</DataList.Label>
                    <DataList.Value>
                      <Badge color="jade" variant="soft" radius="full">
                        {user?.subscription}
                      </Badge>
                    </DataList.Value>
                  </DataList.Item>
                  <DataList.Item>
                    <DataList.Label>Sign up date</DataList.Label>
                    <DataList.Value>{user?.sign_up_date}</DataList.Value>
                  </DataList.Item>
                </DataList.Root>
              </Box>
              <ScrollArea
                type="hover"
                scrollbars="vertical"
                style={{
                  height: '50rem',
                  width: '50rem',
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  borderRadius: '1.5rem',
                  backdropFilter: 'blur(0.625rem)',
                  boxShadow: '0 0.75rem 1.5rem rgba(0, 0, 0, 0.2)',
                  border: '0.0625rem solid rgba(255, 255, 255, 0.3)',
                  margin: '1.5625rem 0',
                  zIndex: 2,
                  padding: '2rem',
                }}
              >
                <h2 style={{ textAlign: 'center' }}>Works</h2>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: '1rem',
                    padding: '1rem',
                  }}
                >
                  {user?.works && Object.keys(user.works).length > 0 ? (
                    Object.keys(user.works).map(key => (
                      <Box className="white-container" 
                      key={key}
                      style={{ 
                        margin: 0,
                        position: 'relative',
                      }}
                      >
                      <div style={{ height: '100%' }}>
                      <Flex align="center" justify="between" style={{ marginBottom: '0.5rem' }}>
                        <Flex align="center" gap="2">
                          <Checkbox
                            size="2"
                            checked={set.has(key)}
                            onCheckedChange={e => handleCheckboxChange(key, e as boolean)}
                          />
                          <Text 
                            as="label" 
                            style={{ 
                              fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, sans-serif',
                              fontWeight: '500',
                              fontSize: '1.2rem',
                              color: '#1A1A1A'
                            }}
                          >
                            {key}
                          </Text>
                        </Flex>
                        <Button
                        className="trash-button"
                        onClick={() => {
                          setPendingDeleteKey(key);
                          setDeleteDialogOpen(true);
                        }}
                        disabled={deletingItems.has(key)}
                      >
                        <Trash stroke="currentColor" fill="none" style={{ width: '0.75rem', height: '0.75rem' }} />
                      </Button>
                      <Dialog.Root open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
                      <Dialog.Content style={{
                        backgroundColor: 'rgba(255, 255, 255, 0.95)',
                        borderRadius: '1rem',
                        padding: '2rem',
                        boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.1)',
                        maxWidth: '30rem',
                        margin: 'auto'
                      }}>
                        <Dialog.Title style={{ fontSize: '1.5rem', fontWeight: 600, marginBottom: '1rem' }}>
                          Confirm Deletion
                        </Dialog.Title>
                        <Dialog.Description style={{ marginBottom: '2rem', fontSize: '1rem' }}>
                          Are you sure you want to delete this work?
                        </Dialog.Description>
                        <Flex justify="end" gap="2">
                          <Dialog.Close>
                            <button 
                              className="pricing-button" 
                              style={{
                                backgroundColor: '#FF4B2B',
                                border: 'none',
                                borderRadius: '8px',
                                padding: '0.75rem 1.5rem',
                                cursor: 'pointer'
                              }}
                            >
                              Cancel
                            </button>
                          </Dialog.Close>
                          <button 
                            className="pricing-button" 
                            style={{
                              backgroundColor: '#FF4B2B',
                              border: 'none',
                              borderRadius: '8px',
                              padding: '0.75rem 1.5rem',
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              deleteWork.mutateAsync(pendingDeleteKey);
                              setDeleteDialogOpen(false);
                            }}
                          >
                            Delete
                          </button>
                        </Flex>
                      </Dialog.Content>
                    </Dialog.Root>
                      </Flex>
                    <div
                      onClick={() => handleLoadWork(key)}
                      onMouseEnter={() => handleMouseEnter(key)}
                      onMouseLeave={() => handleMouseLeave(key)}
                      style={{
                        width: '100%',
                        border: `0.125rem solid ${
                          hoveredStates[key] ? 'orange' : 'rgba(0, 0, 0, 0.1)'
                        }`,
                        borderRadius: '0.75rem',
                        padding: '1rem',
                        transition: 'all 0.2s ease-in-out',
                        outline: 'none',
                        WebkitTapHighlightColor: 'black',
                        cursor: 'pointer',
                        minHeight: hoveredStates[key] ? '12rem' : '4rem',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: hoveredStates[key] ? 'rgba(255, 255, 255, 0.9)' : 'rgba(255, 255, 255, 0.7)',
                        boxShadow: hoveredStates[key]
                          ? '0 0.5rem 1rem rgba(255, 140, 0, 0.1), 0 0 0 2px rgba(255, 140, 0, 0.2)'
                          : '0 0.25rem 0.5rem rgba(0, 0, 0, 0.05)',
                        transform: hoveredStates[key] ? 'translateY(-2px)' : 'translateY(0)',
                      }}
                    >
                      <Dialog.Root open={showLoadDialog} onOpenChange={setShowLoadDialog}>
                          <Dialog.Content>
                            <Dialog.Title>Unsaved Work</Dialog.Title>
                            <Dialog.Description mb="4">
                              Do you want to save your current work before loading?
                            </Dialog.Description>
                            <Flex gap="3" mt="4" justify="end">
                              <Dialog.Close>
                                <Button 
                                  variant="soft" 
                                  onClick={() => {
                                    executeLoad(pendingLoadKey);
                                    setShowLoadDialog(false);
                                  }}
                                >
                                  No, Load Without Saving
                                </Button>
                              </Dialog.Close>
                              <Button 
                                onClick={() => {
                                  setShowLoadDialog(false);
                                  setShowSaveDialog(true);
                                }}
                              >
                                Yes, Save First
                              </Button>
                            </Flex>
                          </Dialog.Content>
                        </Dialog.Root>
                        <Dialog.Root open={showSaveDialog} onOpenChange={setShowSaveDialog}>
                          <Dialog.Content>
                            <Dialog.Title>Save Work</Dialog.Title>
                            <Dialog.Description mb="4">
                              Enter a title for your work
                            </Dialog.Description>
                            <Flex direction="column" gap="3">
                              <label>
                                <Text as="div" size="2" mb="1" weight="bold">
                                  Title
                                </Text>
                                <TextField.Root
                                  placeholder="Enter a title"
                                  value={title}
                                  onChange={(e) => setTitle(e.target.value)}
                                />
                              </label>
                            </Flex>
                            <Flex gap="3" mt="4" justify="end">
                              <Dialog.Close>
                                <Button variant="soft">
                                  Cancel
                                </Button>
                              </Dialog.Close>
                              <Button 
                                onClick={async () => {
                                  if (title) {
                                    await mutateSave.mutateAsync(title);
                                    setShowSaveDialog(false);
                                    executeLoad(pendingLoadKey);
                                  }
                                }}
                                disabled={!title}
                              >
                                Save and Load
                              </Button>
                            </Flex>
                          </Dialog.Content>
                        </Dialog.Root>
                      <Flex direction="column" gap="2">
                        <Badge variant="soft" color="orange" style={{ width: 'fit-content' }}>
                          {user.works[key]?.G}
                        </Badge>
                        <div style={{
                          maxHeight: hoveredStates[key] ? '200px' : '0',
                          overflow: 'hidden',
                          transition: 'all 0.3s ease-in-out',
                          opacity: hoveredStates[key] ? 1 : 0,
                        }}>
                          <Text style={{ 
                            fontSize: '0.9rem', 
                            lineHeight: '1.5',
                            color: '#333',
                            fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, sans-serif',
                          }}>
                            {user.works[key]?.SUM}
                          </Text>
                        </div>
                      </Flex>
                    </div>
                  </div>
                </Box>
                ))
              ) : (
                <Text style={{ textAlign: 'center', width: '100%', gridColumn: '1 / -1' }}>
                  No works found
                </Text>
              )}
                </div>
              </ScrollArea>
            </Flex>
          </div>
          <Footer />
        </Theme>
      </>
    );
  }
}

export default Profile;

